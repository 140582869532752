import { getCodeUrl } from '@/config.js'
let timeout
export function throttle(func, wait) {
    if(timeout)return;
    timeout = setTimeout(() => {
        func()
        timeout = null
    }, wait);
}

export let onceExecute = async (callback) => {
    if(callback){
        callback instanceof Promise ? await callback() : callback()
    }
    onceExecute = function(){ }
}

export function wxAuthorize(href) {
    let uri = encodeURIComponent(href); 
    // 跳转授权
    window.location.href = getCodeUrl(uri);
}

export function formatDuration(seconds) {
    seconds = Math.floor(seconds); // 确保是整数
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = seconds % 60;

    if (h > 0) {
        return [h, m, s].map(v => String(v).padStart(2, "0")).join(":");
    } else {
        return [m, s].map(v => String(v).padStart(2, "0")).join(":");
    }
}
  