import axios from './pcRequest'

export const userApply = (data) => {
	return axios.request({
		url: '/sliver-user/apply',
		method: 'POST',
		data,
	})
}

export const getUnAuthVideoInfo = (key) => {
	return axios.request({
		url: `/silver/getVideoUrl/${key}`,
		method: 'get'
	})
}

export const getVideoInfo = (key) => {
	return axios.request({
		url: `/silver/getVideo/${key}`,
		method: 'get'
	})
}

export const updateVideoProgress = (params) => {
	return axios.request({
		url: `/silver/videoProgress`,
		method: 'get',
		params
	})
}

export const getQuestion = (vid) => {
	return axios.request({
		url: `/silverQuestions/getQuestionByVid?vid=${vid}`,
		method: 'get'
	})
}

export const submitAnswer = (data) => {
	return axios.request({
		url: '/silverQuestions/answer',
		method: 'post',
		data
	})
}

export const saveCustomerPhone = (data) => {
	return axios.request({
		url: '/sliver-user/saveCustomerPhone',
		method: 'post',
		data
	})
}

export const updateOrderStatus = (data) => {
	return axios.request({
		url: '/silverOrder/updateOrderStatus',
		method: 'put',
		data
	})
}