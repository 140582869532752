import { createRouter,createWebHistory } from 'vue-router'
// import { getStorage,setStorage  } from '../utils/auth.js'
import Index from '../pages/index/index'
import caseDetails from '../pages/caseDetails/caseDetails'
import shopDetails from '../pages/shopDetails/shopDetails'
import videoDetails from '../pages/videoDetails/videoDetails'
import shareStaffPage from '../pages/shareStaffPage/shareStaffPage'
import registerSuccess from '../pages/registerSuccess/registerSuccess'
import preView from '../pages/pre-view/preView'
import preViewVideo from '../pages/pre-view-video'
import preViewPdf from '../pages/pre-view-pdf'
import videoWatch from '../pages/videoWatch'

import registrationAgreement from '../pages/registrationAgreement/index'
import appCode from '../pages/appCode/index'
import { onceExecute } from '../utils/utils'
import { getAccessToken } from '@/request'
import { injectConfig } from '@/utils/wxsdk.js'
const routes = [
    { path: '/', component: Index },
    { path: '/caseDetails', component: caseDetails },
    { path: '/shopDetails', component: shopDetails },
    { path: '/videoDetails', component: videoDetails },
    { path: '/share-staff', component: shareStaffPage },
    { path: '/register-success', component: registerSuccess },
    { path: '/register-agreement', component: registrationAgreement },
    { path: '/app-code', component: appCode },
    { path: '/pre-view', component: preView },
    { path: '/pre-view-video', component: preViewVideo },
    { path: '/pre-view-pdf', component: preViewPdf },
    { path: '/video-watch', component: videoWatch }
]

// const notLogin = ['/share-staff','/register-success','/register-agreement','/app-code','/pre-view','/pre-view-video','/pre-view-pdf'];
export const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach(async (to,from,next) => {
    await onceExecute(async () => {
        const config = await getAccessToken({ url: location.href.split('#')[0] })
        injectConfig({
            timestamp: config.timestamp, // 生成签名的时间戳
            nonceStr: config.noncestr, // 生成签名的随机串
            signature: config.sign, // 签名
        })
    })
    // }
    next()
})

