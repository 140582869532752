export const baseURL = process.env.NODE_ENV === 'development' ? 'https://api.9ndada.com' : 'https://api.9ndada.com';
// export const baseURL = process.env.NODE_ENV === 'development' ? 'https://api.9ndada.com' : 'https://api.9ndada.com';

export const pcBaseURL = process.env.NODE_ENV === 'development' ? 'https://admin.9ndada.com/prod-api' : 'https://admin.9ndada.com/prod-api';

export const H5URL = process.env.NODE_ENV === 'development' ? 'http://testapi.9ndada.com' : 'https://h5.9ndada.com/';  //访问地址
export const appid = 'wxbb063bfaaf442d13'
export const socket = process.env.NODE_ENV === 'development' ? 'wss://testapi.9ndada.com' : 'wss://h5.9ndada.com';
export const originalId='gh_c62b2d0086b7'
export const getCodeUrl = (url,state = 123)=>{
    return  `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
}
export const QRcode = process.env.NODE_ENV === 'development' ? 'https://gwapi.9ndada.com' : 'https://gwapi.9ndada.com';
