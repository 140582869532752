<template>
  <div class="video-page" @touchmove="touchmove">
    <meta name="referrer" content="never" />
    <template v-if="[1, 2, 3, 4].includes(data.info?.isExpired)">
      <div class="masker"></div>
      <div class="expire-card">
        <div class="center">
          <div class="title">{{ linkStautsInfo.title }}</div>
          <div class="desc">{{ linkStautsInfo.desc }}</div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- 登录 -->
      <template v-if="data.isLogin && data.auditStatus === 1">
        <!-- 答题状态 -->
        <!-- 成功 -->
        <div class="finish-page" v-if="data.isShowMoneyPage">
          <img src="../../assets/answer-success.png" alt="">
          <div class="title">恭喜您回答正确</div>
          <img src="../../assets/redPacket.png" v-if="!isCollectMoney" alt="" @click="onMoneyConfirm">
          <div class="redPacket" v-else>
            <img src="../../assets/redPacket-open.png" alt="">
            <div class="flex-center money-center">
              <span class="money-text">{{ data.info.rewardNum }}</span>
              <span class="unit">元</span>
            </div>
            <div class="money-tips">红包已经在路上，如遇高峰期延迟 到账属于正常情况，请勿担心</div>
            <div class="collect-money">红包已领取</div>
          </div>
        </div>
        <!-- 失败 -->
        <div class="finish-page fail" v-else-if="answerStatus === 2 && !isContinue">
          <img src="../../assets/answer-failed.png" alt="">
          <div class="title">回答错误</div>
          <div class="text-center">
            <div class="desc w112">3次机会已用光 请下次再接再厉吧</div>
          </div>
        </div>
        <template v-else>
          <div class="video-section">
            <div class="video-box">
              <video
                id="videoId"
                playsinline 
                webkit-playsinline
                :src="data.info?.url"
                :poster="data.info?.cover"
                :controls="false"
                @timeupdate="onTimeupdate"
                @ratechange="onRateChange"
                @ended="onEnded"
              >
              </video>
              <div class="remain-time">{{ data.videoDuration }}</div>
              <img class="play-icon" src="../../assets/play_icon.png" alt="" v-if="!isPlay" @click="onVideoPlay">
              <img class="fullscreen" src="../../assets/fullscreen.png" alt="" @click="onFullScreen">
            </div>
            <div class="title">{{ data.info?.title }}</div>
            <div class="create-time">截止时间：{{ data.info.endTime?.replace("T", " ") }}</div>
          </div>
          <!-- <div class="answer-placeholder" v-if="!data.isPlayFinish">视频完播后 答题领红包</div> -->
          <!-- <template v-else> -->
          <div class="answer-section">
            <div class="header" v-if="!data.info.isReward && !data.info.isAnswer">
              <img class="money" src="../../assets/money.png" alt="">
              <div>本次答题红包：{{ data.info.rewardNum }}元</div>
            </div>
            <div class="content">
              <div class="question">问题：{{ data.questionInfo.content }} ({{ data.questionInfo.type === 'single' ? '单选题' : '多选题' }})</div>
              <div class="answer-list">
                <div class="answer-item" 
                  v-for="(item, index) in data.questionInfo.answer || []" :key="item.answerId" 
                  :class="{ 
                    active: selectAnswerIds.includes(item.answerId),
                    fail: selectAnswerIds.includes(item.answerId) && answerStatus === 2 
                  }" 
                  @click="onTabItemClick(item)"
                >
                  <span class="text-ellipsis">{{ data.options[index] }}. {{ item.answerContent }}</span>
                  <template v-if="answerStatus === 1">
                    <img class="checked" src="../../assets/success.png" alt="" v-if="selectAnswerIds.includes(item.answerId)">
                  </template>
                  <template v-else-if="answerStatus === 2">
                    <img class="checked" src="../../assets/fail.png" alt="" v-if="selectAnswerIds.includes(item.answerId)">
                  </template>
                  <template v-else>
                    <img class="checked" src="../../assets/checked.png" alt="" v-if="selectAnswerIds.includes(item.answerId)">
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="control-box">
            <div class="submit-btn" v-if="[0, 1].includes(answerStatus)" :class="{ disabled: selectAnswerIds.length === 0 || !data.isPlayFinish }" @click="onSubmitClick">{{ answerStatus === 1 ? '回答正确': '提交回答' }}</div>
            <div class="submit-btn fail" v-else @click="onRefreshClick">
              <img class="refresh" src="../../assets/refresh.png" alt="">
              <span>回答错误，点击重新作答</span>
            </div>
          </div>
          <!-- </template> -->
        </template>
      </template>
      <template v-else>
        <template v-if="!data.isLogin && data.isLoading">
          <div class="loading-card">
            <van-loading size="50px" color="#3478F6" vertical>加载中...</van-loading>
          </div>
        </template>
        <template v-else>
          <div class="masker"></div>
          <div class="apply-card" :class="{ 'black-card': data.auditStatus === 2 }" v-if="data.isLogin && [0, 2].includes(data.auditStatus)">
            <div class="center">
              <div class="title">
                <template v-if="data.auditStatus === 0">已提交申请</template>
                <template v-else-if="data.auditStatus === 2">您已被关进小黑屋</template>
              </div>
              <div class="desc">
                <template v-if="data.auditStatus === 0">请联系客服审核</template>
                <template v-else-if="data.auditStatus === 2">请联系客服解除限制</template>
              </div>
              <div class="user-info">
                <img class="avatar" :src="data.userInfo.avatar" alt="">
                <div class="nickname">{{ data.userInfo.nickname }}</div>
              </div>
            </div>
          </div>
          <template v-else>
            <div class="loading-card" v-if="!data.info?.title">
              <van-loading size="50px" color="#3478F6" vertical>加载中...</van-loading>
            </div>
            <div class="video-card" v-else>
              <img :src="data.info.cover" alt="">
              <div class="title">{{ data.info.title }}</div>
              <div class="learn-btn" @click="handleLogin">去学习</div>
            </div>
          </template>
        </template>
      </template>
    </template>
    <div class="masker" v-show="data.isShowRemarkDialog"></div>
    <div class="remark-dialog" v-show="data.isShowRemarkDialog">
      <div class="title">设置备注</div>
      <img class="avatar" :src="data.userInfo.avatar" />
      <div class="nickname">{{ data.userInfo.nickname }}</div>
      <div class="form">
        <!-- <div class="form-item">
          <div class="text">姓名：</div>
          <input class="input" v-model="data.username" placeholder="请填写姓名">
        </div> -->
        <div class="form-item2">
          <div class="text">电话：</div>
          <input class="input" v-model="data.phone" type="number" placeholder="请填写电话">
        </div>
      </div>
      <div class="flex-center">
        <div class="btn-item save-btn" @click="onRemardConfirm">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { defineComponent, reactive, watch, ref, onMounted, computed } from "vue"; 
import { userApply, getVideoInfo, getQuestion, getUnAuthVideoInfo, updateVideoProgress, submitAnswer, updateOrderStatus, saveCustomerPhone } from "@/request/course";
import { H5URL } from "@/config.js";
import { wxLogin } from "@/request";
import { getStorage, setStorage } from "../../utils/auth.js";
import { wxAuthorize, formatDuration } from "../../utils/utils";
import { pathUtils, requestMerchantTransfer } from "../../utils/wxsdk";
import { Toast } from "vant";

export default defineComponent({
  name: "videoWatch",
  setup() {
    const router = useRouter();
    let query = router.currentRoute.value.query;
    console.log("query: ", query);

    const data = reactive({
      info: {},
      userInfo: {},
      isLogin: !!getStorage("info"),
      auditStatus: null, // 0审核中 1审核通过 2黑名单
      isPlayFinish: false,
      questionInfo: {},
      options: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "G", "K", "L"],
      isLoading: false,
      videoDuration: "",
      username: "",
      phone: '',
      isShowRemarkDialog: false,
      isShowMoneyPage: false
    });

    const statusMap = {
      1: { title: '链接已过期', desc: '请联系客服了解详情' },
      2: { title: '课程已结束', desc: '请联系客服了解详情' },
      3: { title: '暂无权限', desc: '请联系其他同事' },
      4: { title: '课程未开始', desc: '请联系客服了解详情' },
    }
    const linkStautsInfo = computed(() => {
      return statusMap[data.info?.isExpired] || {};
    });

    // 获取视频信息
    const getVideoDetailInfo = async () => {
      try {
        const res = await getVideoInfo(query.key);
        console.log('获取视频信息：', res)
        if (res.code === 200) {
          data.info = { ...res.data }
          if (data.info?.watchTime >= data.info?.realTime) {
            data.isPlayFinish = true;
          }
        }
        const videoId = res.data.vid;
        if (videoId) {
          getQuestion(videoId).then(res => {
            data.questionInfo = res.data || {};
          });
        }
      } catch (error) {
        console.log('请求出错：', error);
      }
    }

    // 用户申请
    const applyUserHandle = () => {
      userApply({
        sourceUid: query.uid,
        sourceCompanyId: query.companyId
      }).then(res => {
        data.auditStatus = res.data.status;
        data.userInfo = {
          nickname: res.data.targetUserName,
          avatar: res.data.targetHeadimage
        }
        if (data.auditStatus === 1) {
          getVideoDetailInfo();
        }
      })
    }

    watch(() => data.isLogin, (newValue) => {
      if (!newValue) return;
      applyUserHandle();
    }, { immediate: true });


    onMounted(async () => {
      if (query.code && !getStorage("info")) {
        data.isLoading = true;
        try {
          const info = await wxLogin({
            code: query.code,
            JSurl: H5URL + "?code=" + query.code + "&state=123",
            uid: null,
          });
          setStorage("info", JSON.stringify(info));
          data.isLoading = false;
          data.isLogin = true;
        } catch (err) {
          console.log(err);
          data.isLoading = false;
        }
      }

      // 获取视频信息
      if (!getStorage("info")) {
        const videoInfo = await getUnAuthVideoInfo(query.key);
        console.log(videoInfo);
        if (videoInfo.code === 200) {
          data.info = { ...videoInfo.data }
        }
      }
    })

    const handleLogin = () => {
      pathUtils((path, par) => {
        if (par.uid) {
          path += "?uid=" + par.uid;
        }
        if (par.companyId) {
          path += "&companyId=" + par.companyId;
        }
        if (par.key) {
          path += "&key=" + par.key;
        }
        wxAuthorize(path);
      });
    };

    const touchmove = (e) => {
      if (!data.isLoading || data.isShowRemarkDialog) {
        e.preventDefault();
      }
    };

    const isPlay = ref(false);
    let lastTime = 0, quickLastTime = 0;
    const onVideoPlay = () => {
      isPlay.value = true;
      const video = document.querySelector("#videoId");
      if (data.info?.watchTime === data.info?.realTime) {
        video.currentTime = 0;
        quickLastTime = 0;
      } else {
        video.currentTime = data.info?.watchTime || 0;
        quickLastTime = data.info?.watchTime || 0;
      }
      setTimeout(() => {
        video.play();
      }, 300);
    }

    const onTimeupdate = () => {
      const video = document.querySelector("#videoId");
      const currentTime = Math.floor(video.currentTime); // 取整秒
      data.videoDuration = formatDuration(video.duration - video.currentTime);
      if (video.currentTime > quickLastTime + 2) { // 允许 2s 误差
        video.currentTime = quickLastTime; // 用户快进后强制回退
      } else {
        quickLastTime = video.currentTime; // 记录正常播放时间
      }
      if (currentTime !== lastTime) {
        lastTime = currentTime;
        updateVideoProgress({
          sec: currentTime,
          realTime: data.info.realTime,
          relId: data.info.relId
        });
      }
    }

    const onRateChange = () => {
      const video = document.querySelector('#videoId');
      video.playbackRate = 1.0;
    }

    const onEnded = () => {
      console.log('视频播放结束');
      isPlay.value = false;
      data.isPlayFinish = true;
      updateVideoProgress({
        sec: data.info.realTime,
        realTime: data.info.realTime,
        relId: data.info.relId
      });
    }

    const onFullScreen = () => {
      const videoBox = document.querySelector(".video-box");
      if (!document.fullscreenElement) {
        // 进入全屏
        if (videoBox.requestFullscreen) {
          videoBox.requestFullscreen();
        } else if (videoBox.webkitRequestFullscreen) { // Safari
          videoBox.webkitRequestFullscreen();
        } else if (videoBox.msRequestFullscreen) { // IE11
          videoBox.msRequestFullscreen();
        }
      } else {
        // 退出全屏
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { // Safari
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE11
          document.msExitFullscreen();
        }
      }
    }

    const selectAnswerIds = ref([]);
    const isContinue = ref(1);
    const answerStatus = ref(0); // 1 成功 2：失败
    const redPackOrderInfo = ref({});
    const onTabItemClick = (item) => {
      if (answerStatus.value !== 0) return;
      if (data.questionInfo.type === 'single') {
        selectAnswerIds.value = [item.answerId];
      } else {
        if (selectAnswerIds.value.includes(item.answerId)) {
          const index = selectAnswerIds.value.findIndex(it => it === item.answerId);
          selectAnswerIds.value.splice(index, 1);
        } else {
          selectAnswerIds.value.push(item.answerId);
        }
      }
    }

    const onSubmitClick = async () => {
      if (!selectAnswerIds.value.length || !data.isPlayFinish) return;
      if (answerStatus.value == 1) return;
      const { id: questionId } = data.questionInfo;
      const res = await submitAnswer({
        relId: data.info.relId, // 课程Id
        questionId,
        optionIds: selectAnswerIds.value
      });
      console.log('提交答案：', res);
      if (res.code === 200) {
        if (res.data.isCorrect === 1) {
          answerStatus.value = 1;
          redPackOrderInfo.value = res.data.redPackOrderInfo;
          if (data.info.isAnswer == 0 && res.data.isReward == 0) { // 答题完成未填写备注
            data.isShowRemarkDialog = true;
          } else if (!data.info.isReward && res.data.isReward) { // 答题完成未领取红包
            data.isShowMoneyPage = true;
          }
          return;
        }
        isContinue.value = res.data.isContinue;
        answerStatus.value = 2;
      }
    }

    const onRefreshClick = () => {
      answerStatus.value = 0;
      selectAnswerIds.value = [];
    }

    const onRemardConfirm = async () => {
      // if (!data.username) {
      //   return Toast('请输入用户名');
      // }
      if (!data.phone) {
        return Toast('请填写手机号');
      }
      let reg = /^[1][3-9][0-9]{9}$/;
      if (!reg.test(data.phone)){
        return Toast('手机号格式不正确');
      }
      const res = await saveCustomerPhone({
        relId: data.info.relId,
        customerPhone: data.phone,
      });
      if (res.code === 200) {
        data.isShowRemarkDialog = false;
        data.isShowMoneyPage = true;
      }
    }

    const isCollectMoney = ref(false);
    const onMoneyConfirm = async () => {
      if (redPackOrderInfo.value.state === 'ERROR') {
        return Toast(redPackOrderInfo.value.msg);
      }
      const res = await requestMerchantTransfer(redPackOrderInfo.value);
      console.log(res);
      if (res.code != 1) {
        return Toast('你的微信版本过低，请更新至最新版本。');
      }
      const sendRes = await updateOrderStatus({
        outBillNo: redPackOrderInfo.value.outBillNo,
      });
      console.log('sendRes:', sendRes);
      isCollectMoney.value = true;
    }

    return {
      touchmove,
      data,
      linkStautsInfo,
      query,
      handleLogin,
      isPlay,
      onVideoPlay,
      onTimeupdate,
      onRateChange,
      onEnded,
      onFullScreen,
      isContinue,
      answerStatus,
      selectAnswerIds,
      onTabItemClick,
      onRefreshClick,
      onSubmitClick,
      // onRemarkCancel,
      onRemardConfirm,
      onMoneyConfirm,
      isCollectMoney
    };
  },
  beforeUnmount() {
    window.removeEventListener("scroll", () => {});
  },
});
</script>

<style lang="scss" scope>
html,
body,
#app {
  height: 100%;
}

.video-page {
  position: relative;
  height: 100vh;
  background-color: #F7F7F7; 
}

.video-section {
  padding: 20px 15px;
  background-color: #fff;
  margin-bottom: 8px;

  .video-box {
    position: relative;
    width: 100%;
    height: 210px;
    background-color: #000;
  }

  #videoId {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;

    &::--webkit-media-controls {
      display: none!important;
    }
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
  }

  .fullscreen {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 30px;
    height: 30px;
  }

  .remain-time {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #333333;
    font-size: 18px;
  }

  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    margin-top: 12px;
  }

  .create-time {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    line-height: 17px;
  }
}

.answer-section {
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    border-bottom: 1px solid #F2F2F4;
    color: #EA5427;
    font-size: 14px;
    line-height: 20px;

    .money {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  .content {
    padding: 20px 15px;

    .question {
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .answer-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 40px;
      padding: 0 12px;
      box-sizing: border-box;
      background: #F5F7FA;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      overflow: hidden;

      .checked {
        width: 14px;
        height: 14px;
      }

      .text-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &:not(:last-of-type) {
        margin-bottom: 4px;
      }

      &.active {
        background-color: #DCE7FA;
        color: #3478F6;
        font-weight: bold;
      }

      &.fail {
        background-color: #FFE6DD;
        color: #EA5427;
      }
    }
  }
}

.control-box {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  padding-bottom: 20px;

  .submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 34px;
    background: #3478F6;
    border-radius: 17px 17px 17px 17px;
    text-align: center;
    line-height: 34px;
    color: #fff;

    .refresh {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }

    &.disabled {
      background: rgba(52, 120, 246, .6)
    }

    &.fail {
      background: #EA5427;
    }
  }
}

.answer-placeholder {
  width: 100%;
  height: 44px;
  font-size: 14px;
  color: #666666;
  line-height: 44px;
  text-align: center;
  background-color: #fff;
}

.masker {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
}

.video-card {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 289px;
  height: 302px;
  background: #FAFBFC;
  border-radius: 20px 20px 20px 20px;
  padding: 32px 20px;
  box-sizing: border-box;

  img {
    width: 249px;
    height: 152px;
    display: block;
    margin-bottom: 8px;
    object-fit: cover;
  }

  .title {
    font-size: 14px;
    color: #333333;
    text-align: center;
    margin-bottom: 32px;
  }

  .learn-btn {
    width: 249px;
    height: 34px;
    background: #3478F6;
    border-radius: 17px 17px 17px 17px;
    color: #fff;
    text-align: center;
    line-height: 34px;;
  }

}

.apply-card {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 299px;
  height: 345px;
  background: url(../../assets/apply-dialog.png) no-repeat;

  &.black-card {
    background-image: url(../../assets/black-dialog.png);
  }

  .center {
    position: absolute;
    top: 140px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
  }

  .title {
    font-size: 26px;
    color: #333;
    font-weight: bold;
    text-align: center;
  }

  .desc {
    font-size: 14px;
    color: #333333;
    line-height: 21px;
    text-align: center;
    margin-top: 4px;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    .avatar {
      width: 55px;
      height: 55px;
      box-shadow: 0px 3px 6px 1px rgba(91,116,165,0.16);
      border: 3px solid #FFFFFF;
      border-radius: 50%;
    }

    .nickname {
      font-size: 16px;
      color: #333333;
      line-height: 21px;
      text-align: center;
      margin-top: 8px;
      font-weight: bold;
    }
  }
}

.expire-card {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 299px;
  height: 265px;
  background: url(../../assets/expire-dialog.png) no-repeat;

  .center {
    position: absolute;
    top: 156px;
    left: 50%;
    transform: translateX(-50%);
  }

  .title {
    font-size: 26px;
    color: #333;
    font-weight: bold;
    text-align: center;
  }

  .desc {
    font-size: 14px;
    color: #333333;
    line-height: 21px;
    text-align: center;
    margin-top: 4px;
  }
}

.finish-page {
  text-align: center;

  img {
    width: 100%;
    display: block;
  }

  .title {
    font-size: 20px;
    color: #333;
    text-align: center;
    margin-top: 32px;
  }

  .text-center {
    display: flex;
    justify-content: center;
  }

  .desc {
    max-width: 210px;
    font-size: 14px;
    color: #333333;
    line-height: 21px;
    text-align: center;
    margin-top: 8px;

    &.w112 {
      max-width: 112px;
    }
  }
}

.loading-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7)
}

.flex-center,
.flex-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-start {
  justify-content: flex-start;
}

.remark-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 295px;
  height: 330px;
  // height: 398px;
  background-color: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 16px;
    color: #333333;
    margin: 24px 0;
  }
  
  .avatar {
    width: 42px;
    height: 42px;
    margin-bottom: 6px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .nickname {
    font-size: 14px;
    color: #333;
  }
  
  .form {
    width: 265px;
    margin-top: 44px;
  }
  
  .form-item {
    margin-bottom: 12px;
  }
  
  .form-item2 {
    margin-bottom: 32px;
  }
  
  .text {
    font-size: 12px;
    color: #666;
    margin-bottom: 6px;
  }
  
  .input {
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
    height: 36px;
    background-color: #EDF2FA;
    border-radius: 4px;
    color: #333333;
    font-size: 14px;
    border: none;
  }
  
  .btn-item {
    height: 34px;
    background: #FFFFFF;
    border-radius: 17px;
    padding: 0 50px;
    border: 1px solid #446CE2;
    text-align: center;
    line-height: 34px;
    color: #3478F6;
    font-size: 13px;
  }
  
  .save-btn {
    background: #3478F6;
    color: #fff;
    // margin-left: 13px;
  }
}

.redPacket {
  position: relative;

  .money-center {
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #EA5427;

    .money-text {
      font-size: 64px;
      line-height: 70px;
      font-weight: bold;
      margin-right: 8px;
    }

    .unit {
      position: relative;
      bottom: -10px;
      font-size: 24px;
      line-height: 70px;
    }
  }

  .money-tips {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 230px;
    width: 210px;
    height: 42px;
    font-weight: 400;
    font-size: 14px;
    color: #FDF4BC;
    line-height: 22px;
    text-align: center;
  }

  .collect-money {
    position: absolute;
    top: 290px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 23px;
    line-height: 22px;
    color: #FAE14D;
    text-align: center;
  }
}
</style>
