import axios from './request'

export const getArticle = (data) => {
	return axios.request({
		url: '/staffapi/noAuth/article',
		method: 'POST',
		data,
	})
}
export const wxLogin = (params) => {
	return axios.request({
		url: '/official/callBack2',
		method: 'GET',
		params,
	})
}

export const getAccessToken = (params) => {
	return axios.request({
		url: '/official/getAccessToken',
		method: 'GET',
		params,
	})
}
// 获取文章发布人用户信息
export const getUserInfo = (data) => {
	return axios.request({
		url: '/Advertising/noAuth/selAdvertiseCard',
		method: 'POST', 
		data,
	})
}

// 获取广告
export const getAdvertising = (data) => {
	return axios.request({
		url: '/Advertising/selAdvertise',
		method: 'POST',
		data,
	})
}
// 根据广告ID和用户ID查询用户广告视频设置
export const getAdvertiseSeeding = (data) => {
	return axios.request({
		url: '/Advertising/getAdvertiseSeedingById',
		method: 'POST',
		data,
	})
}
// 获取商品信息
export const getGoodsInfo = (data) => {
	return axios.request({
		url: '/product/goodsInfo',
		method: 'POST',
		data,
	})
}
// 我的店铺
export const getShopInfo = (data) => {
	return axios.request({
		url: '/storeOrders/shopInfo',
		method: 'POST',
		data,
	})
}
//添加浏览次数
export const addBrowsing = (data) => {
	return axios.request({
		url: '/browsing/addBrowsing',
		method: 'POST',
		data,
	})
}
// 消息推送
export const pushShareTest = (data) => {
	return axios.request({
		url: '/push/customerPush',
		method: 'POST',
		data,
	})
}
// 获取微信跳转参数
export const getWxShareParams = (params) => {
	return axios.request({
		url: "/shareRecord/noAuth/shareRecordById",
		method: "GET",
		params
	})
}
// 提交表单内容
export const addShareForm = (data) => {
	return axios.request({
		url: "/form/addShareForm",
		method: "POST",
		data
	})
}

// 推送给分享人消息
export const pushShareMessage = (data) => {
	return axios.request({
		url: "/message/pushMessage",
		method: "POST",
		data
	})
}

// 添加浏览记录
export const setShareRecord = (data) => {
	return axios.request({
		url: "/shareRecord/saveShareRelationH5",
		method: "POST",
		data
	})
}

// 获取pdf详情

export const getPdfFile = (params) => {
	return axios.request({
		url: "/case/caseUrl",
		method: "GET",
		params
	})
}
//
export const getInfobyId = (data) => {
	return axios.request({
		url: "/staffapi/selInfo",
		method: "POST",
		data
	})
}

//员工注册
export const registerManager = (data) => {
	return axios.request({
		url: "/user/inviteJL",
		method: "POST",
		data
	})
}

// 获取验证码code/generateCode
export const getCode = (data) => {
	return axios.request({
		url: '/code/generateCode',
		method: 'POST',
		data,
	})
}

// 获取注册协议
export const getRegistrationAgreement = (data) => {
	return axios.request({
		url: '/agreement/selRegister',
		method: 'POST',
		data,
	})
}
// 获取隐私协议
export const getSelPrivacy = (data) => {
	return axios.request({
		url: '/agreement/selPrivacy',
		method: 'POST',
		data,
	})
}


//文章详情
export const preViewArticle = (data,url = '/articleVideo/mySelectType') => {
	return axios.request({
		url,
		method: 'POST',
		data,
	})
}
