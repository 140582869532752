<template>
  <div class="tips">
    <div>
      <div class="tips-text">点击右上角浏览器打开</div>
      <div class="tips-text" style="margin-top: 15px">下载小牛叮当最新版</div>
    </div>
    <img
      class="tips-finger"
      src="https://xnddapp.oss-cn-beijing.aliyuncs.com/node/shuozhishan.gif"
      alt=""
    />
  </div>
  <div class="wrapper">
    <img class="wrapper-icon" src="../../assets/311617341774_.pic.jpg" alt="" />
    <div class="title-body">
      <img class="title-icon" src="../../assets/anzuo.png" alt="" />
      <div>小牛叮当</div>
    </div>
    <img
      class="wrapper-icon"
      src="http://xnddapp.oss-cn-beijing.aliyuncs.com/node/1636533508130.jpg"
      alt=""
    />
    <van-button
      v-if="!isWxChat"
      :url="`https://xnddapp.oss-cn-beijing.aliyuncs.com/${apk_path || 'com.letui.app_1.9.888.apk'}`"
      style="border-radius: 5px; width: 169px; height: 40px; margin-top: 40px"
      type="primary"
      >立即安装</van-button
    >
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import { QRcode } from "@/config";

export default defineComponent({
  name: "",
  props: {},
  data() {
    return {
      apk_path: "",
      isWxChat:
        window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
        "micromessenger",
    };
  },
  created() {
    console.log(this.isWxChat);
    axios({
      url: QRcode + "/common/get_code",
      method: "get",
    })
      .then((res) => {
        this.apk_path = res.data.data.apk_path;
      })
      .catch((err) => {
        console.log(err);
      });
  },
});
</script>

<style lang="scss" scoped>
.tips-text {
  width: 160px;
  text-align: center;
  color: rgba(7, 107, 242, 1);
}
.tips {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 95px;
  background: #ebf1fa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.title-icon {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}
.wrapper-icon {
  width: 131px;
  height: 131px;
}
.title-body {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.tips-finger {
  position: fixed;
  right: 0;
  top: 0;
  height: 80px;
  width: 80px;
}
</style>
