import WXSDK from 'weixin-js-sdk'
import { appid } from '@/config.js'
export const jsApiList = [
    'previewImage',
    'wx-open-launch-weapp',
    'updateTimelineShareData',
    'updateAppMessageShareData',
    'requestMerchantTransfer'
]
export const openTagList = [
    'wx-open-launch-weapp',
    'wx-open-subscribe'
]
/**
 * sdk配置注入
 * @param Object params 
*/
export function injectConfig(params){
    WXSDK.config(Object.assign({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appid, // 必填，公众号的唯一标识
        jsApiList, // 必填，需要使用的JS接口列表
        openTagList
    },params))
}
export function reviewImg(event){
    if(typeof event === 'string'){
        WXSDK.previewImage({
            current: event, // 当前显示图片的http链接
            urls: [event] // 需要预览的图片http链接列表
        });
    }else if(event.target.tagName === 'IMG'){
        WXSDK.previewImage({
            current: event.target.src, // 当前显示图片的http链接
            urls: [event.target.src] // 需要预览的图片http链接列表
        });
    }
}

export function shareWxTimeline(title,link,imgUrl){
    WXSDK.updateTimelineShareData({ 
        title, // 分享标题
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success: () => {
            // 设置成功
            // console.log('ready Wechat circle of friends success!');
        }
    })
}

export function shareWxFriend(title,desc,link,imgUrl){
    WXSDK.updateAppMessageShareData({ 
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success: () => {
            // 设置成功
            console.log('ready Wechat friends success!');
        }
    })
}

export function pathUtils(callback){
    let path = window.location.href.split('?')[0];
    let params = window.location.href.split('?')[1];
    let par = Object.create(null);
    if(params){
        par = params.split('&').reduce((current,item)=>{
            let s = item.split('=') || [];
            s[0] && (current[s[0]] = s[1]);
            return current
        },{})
    }
    callback && callback(path,par);
    if(par?.shareRecordId){
        path += '?shareRecordId=' + par.shareRecordId
    }
    return path
}

export function requestMerchantTransfer(params) {
    return new Promise((resolve) => {
        WXSDK.checkJsApi({
            jsApiList: ['requestMerchantTransfer'],
            success: function (res) {
                if (res.checkResult['requestMerchantTransfer']) {
                    WeixinJSBridge.invoke('requestMerchantTransfer', {
                        mchId: params.mchId,
                        appId: params.appId,
                        package: params.packageInfo,
                    },
                    function (res) {
                        console.log("执行完成：", res);
                        if (res.err_msg === 'requestMerchantTransfer:ok') {
                            // res.err_msg将在页面展示成功后返回应用时返回success，并不代表付款成功
                            resolve({ code: 1, res });
                        }
                    });
                } else {
                    resolve({ code: 0 })
                }
            }
        });
    })
}
