import { createApp } from 'vue';
/*轻量、可靠的移动端 Vue 组件库*/ 
import vant from 'vant';
import { Toast } from 'vant';
import { Loading } from 'vant';
import 'vant/lib/index.css';
import { store } from './store'
import { router } from './router'
import App from './App.vue'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

const app = createApp(App)

process.env.NODE_ENV !== "development" && Sentry.init({
    app,
    dsn: "http://f917e7e0a40a4ff792b32c652bbdce19@82.157.195.122:9000/3",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    release: '1.0.0',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
app.component('remote-script', {
    render: (createElement) => <script src={createElement.src}></script>,
    props: {
        src: {
            type: String,
            required: true
        }
    }
})
app.use(store).use(router).use(vant).use(Toast).use(Loading).use(VueVideoPlayer).mount('#app')
